import { useDispatch, useSelector } from "react-redux";
import "./newUser.scss";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { StarRateTwoTone } from "@material-ui/icons";
import { clearErrors } from "../../actions/productAction";
import { NEW_GENDER_CATEGORY_FAIL, NEW_GENDER_CATEGORY_RESET } from "../../constants/categoryConstant"
import { createGenderCategory } from "../../actions/categoryAction";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

export default function NewGenderCategory() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const navigate = useNavigate();
    const { loading, error, success } = useSelector((state) => state.createGenderCategoryReducer);
    const [title, setTitle] = useState("");

    useEffect(() => {
        if(error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if(success) {
            alert.success("Gender Category has been created Successfully!");
            navigate("/");
            dispatch({ type: NEW_GENDER_CATEGORY_RESET });
        }

    }, [dispatch, alert, error, success]);
    
    const createGenderSubmitHandler = (e) => {
        e.preventDefault();
        const myForm = new FormData();
        myForm.set("type", title);
        dispatch(createGenderCategory(myForm));
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="bottom">
                    <form
                        
                        encType="multipart/form-data"
                        onSubmit={createGenderSubmitHandler}
                    >
                        <div className="formInput">
                            <label>Gender Category Name</label>
                            <input type="text"
                                placeholder="Gender Category Name"
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                className="input"
                            />
                        </div>
                        <button className="newUserButton" type="submit">Create</button>
                    </form>
                </div>
            </div>
        </div>
    )
}