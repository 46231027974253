export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const NEW_ORDER_REQUEST = "NEW_ORDER_REQUEST";
export const NEW_ORDER_FAIL = "NEW_ORDER_FAIL";

export const NEW_ORDER_RESET = "NEW_ORDER_RESET";


export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";

export const UPDATE_ORDER_RESET = "UPDATE_ORDER_RESET";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";



export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_RESET = "DELETE_ORDER_RESET";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";

export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
export const GET_ALL_ORDER_FAIL = "GET_ALL_ORDER_FAIL";
export const GET_ALL_ORDER_REQUEST = "GET_ALL_ORDER_REQUEST";

export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL";
export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";

export const GET_ORDER_ITEM_DETAIL_SUCCESS = "GET_ORDER_ITEM_DETAIL_SUCCESS";
export const GET_ORDER_ITEM_DETAIL_FAIL = "GET_ORDER_ITEM_DETAIL_FAIL";
export const GET_ORDER_ITEM_DETAIL_REQUEST = "GET_ORDER_ITEM_DETAIL_REQUEST"