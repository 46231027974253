import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { clearErrors, createProduct, getAllCategories } from "../../actions/productAction";
import { NEW_PRODUCT_RESET } from "../../constants/type";

const New = ({ inputs, title, socket }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, success } = useSelector((state) => state.createProductReducer);
  const { allCategories } = useSelector((state) => state.getAllCategoryReducer);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [apparel, setApparel] = useState("");
  const [gender, setGender] = useState("");
  const [brand, setBrand] = useState("");
  const [stock, setStock] = useState(0);
  const [files, setFiles] = useState([]);
  const [imagesPreview, setImagesReview] = useState([]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors);
    }

    if (success) {
      alert.success("Product Created Successfully");
      navigate("/");
      dispatch({ type: NEW_PRODUCT_RESET });
    }

    dispatch(getAllCategories());
  }, [dispatch, error, success]);

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("description", description);
    myForm.set("stock", stock);
    myForm.set("apparelName", apparel);
    myForm.set("genderName", gender);
    myForm.set("brandName", brand);

    // myForm.append("images", file, file.name);
    for(let i = 0; i < files.length; i++) {
      myForm.append(`images[${i}]`, files[i]);
    }
    dispatch(createProduct(myForm));
  }

  var apparelList = [];
  allCategories.apparels &&
    allCategories.apparels.forEach((item) => {
      apparelList.push({
        id: item.id,
        type: item.type,
      });
    });

  var brandList = [];
  allCategories.brands &&
    allCategories.brands.forEach((item) => {
      brandList.push({
        id: item.id,
        type: item.type
      });
    });

  var genderList = [];
  allCategories.genders &&
    allCategories.genders.forEach((item) => {
      genderList.push({
        id: item.id,
        type: item.type
      });
    });

  // const onFileChange = (event) => {
  //   setFile(event.target.files);
  // }



  //   const files = Array.from(e.target.files[0]);




  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setImagesReview([...imagesPreview, reader.result]);
  //         setFile([...file, reader.result]);
  //       }
  //     };

  //     reader.readAsDataURL(file);

  //   })


  // };
  console.log(files);
  // console.log(imagesPreview);



  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar socket={socket} />
        {/* <div className="top">
          <h1>{title}</h1>
        </div> */}
        <div className="bottom">
          <div className="left">
            {/* <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            /> */}
          </div>
          <div className="right">
            <form
              className="addProductForm"
              encType="multipart/form-data"
              onSubmit={createProductSubmitHandler}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  name="avatar"
                  id="file"
                  onChange={(e) => setFiles(e.target.files)}
                  style={{ display: "none" }}
                  
                />
              </div>

              {/* {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input type={input.type} placeholder={input.placeholder} />
                </div>
              ))} */}
              <div className="formInput">
                <label>Product Name</label>
                <input
                  type="text"
                  placeholder="Product Name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div id="createProductFormImage">
                  {imagesPreview.map((image, index) => (
                    <img key={index} src={image} alt="Product Preview" />
                  ))}
                </div>
              </div>

              <div className="formInput">
                <label>Price</label>
                <input
                  type="number"
                  placeholder="0"
                  required
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Description"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Stock</label>
                <input
                  type="number"
                  placeholder="Stock"
                  required
                  onChange={(e) => setStock(e.target.value)}
                />
              </div>



              <div className="formInput">
                <select onChange={(e) => setApparel(e.target.value)}>
                  <option value="">Choose Apparel Category</option>
                  {apparelList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <select onChange={(e) => setBrand(e.target.value)}>
                  <option value="">Choose Brand Category</option>
                  {brandList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <select onChange={(e) => setGender(e.target.value)}>
                  <option value="">Choose Gender Category</option>
                  {genderList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <label>Active</label>
                <select name="active" id="active">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <button type="submit">Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
