import axios from "axios";
import { Base64 } from "js-base64";
import {AUTH_DETAILS_COOKIE, HANDLE_SIGN_IN, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_OUT} from "../constants/authConstant";
import Cookies from "js-cookie";

const LOGIN_URL = "https://kaitoshop.io.vn/api/auth";
// const LOGIN_URL = "http://localhost:8083/api/auth";

export const signIn = (userName, password) => async dispatch => {
    const hash = Base64.encode(`${userName}:${password}`);

    axios.defaults.headers.common['Authorization'] = `Basic ${hash}`;
    const response = await axios.post(LOGIN_URL + "/authenticate").catch(err => {
        dispatch({
            type: HANDLE_SIGN_IN_ERROR,
            payload: err.message
        })
    });

    if(response) {
        if(response.data.jwt) {
            dispatch({
                type: HANDLE_SIGN_IN,
                payload: JSON.parse(JSON.stringify(response.data))
            });
            
            localStorage.setItem("user", JSON.stringify(response.data))
            window.location.replace('/');
        } else {
            dispatch({
                type: HANDLE_SIGN_IN_ERROR,
                payload: response.data.error
            })
        }
    }
}

export const signOut = () => {
    localStorage.removeItem("user");
    return {
        type: HANDLE_SIGN_OUT
    }
}