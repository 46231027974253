import { combineReducers } from "redux";

import {
        getAllProductReducer, productReducer, createProductReducer, getAllCategoryReducer,
        getProductDetailReducer
} from "./productReducer";

import {
        getAllOrderReducer, getOrderDetailReducer, changeOrderReducer,
        getOrderItemDetailReducer
} from "./orderReducer";

import { getCountAllReducer, getDailyRevenueReducer, getMonthRevenueReducer, get6LastMonthRevenueReducer } from "./calculateReducer";

import message from "./message";
import { signInReducer } from "./auth";
import { createApparelCategoryReducer, createApprelImageReducer, createBrandCategoryReducer, createBrandImageReducer, createGenderCategoryReducer } from "./categoryReducer"

import { changeUserReducer, getAllUserReducer, getUserDetailReducer } from "./userReducer";
const rootReducer = combineReducers({
        getAllProductReducer,
        productReducer,
        createProductReducer,
        getAllCategoryReducer,
        getProductDetailReducer,
        getAllOrderReducer,
        getOrderDetailReducer,
        changeOrderReducer,
        getOrderItemDetailReducer,
        getCountAllReducer,
        changeUserReducer,
        getAllUserReducer,
        getUserDetailReducer,
        signInReducer,
        message,
        getDailyRevenueReducer,
        getMonthRevenueReducer,
        get6LastMonthRevenueReducer,
        createApparelCategoryReducer,
        createApprelImageReducer,
        createBrandCategoryReducer,
        createBrandImageReducer,
        createGenderCategoryReducer
});

export default rootReducer;