export const NEW_USER_SUCCESS = "NEW_USER_SUCCESS";
export const NEW_USER_REQUEST = "NEW_USER_REQUEST";
export const NEW_USER_FAIL = "NEW_USER_FAIL";

export const NEW_USER_RESET = "NEW_USER_RESET";


export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";

export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";



export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_RESET = "DELETE_USER_RESET";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";
export const GET_ALL_USER_REQUEST = "GET_ALL_USER_REQUEST";

export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL";
export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST";