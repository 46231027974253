import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert";
import { getAllOrder, deleteOrder } from "../../actions/orderAction"
import React, { Fragment, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./datatable.scss";
import ViewOrder from "./ViewOrder";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CSVLink } from "react-csv";
import axios from "axios";
import { DELETE_ORDER_REQUEST, DELETE_ORDER_RESET } from "../../constants/orderConstant";




const OrderDataTable = () => {
  // const [data, setData] = useState(userRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { orders } = useSelector(state => state.getAllOrderReducer);
  const { isDeleted } = useSelector(state => state.changeOrderReducer);

  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const [showModalView, setShowModalView] = useState(false);
  const [order, setOrder] = useState({});
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(0);


  const headers = [
    { label: "ID", key: "id" },
    { label: "Price", key: "price" },
    { label: "Customer", key: "customer" },
    { label: "Status", key: "status" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Order Date", key: "date" },
    { label: "Phone", key: "phone" }
  ]

  const getRequestParams = (page1, pageSize1) => {
    let params = {};
    if (page1) {
      params["page"] = page1 - 1;
    }
    if (pageSize1) {
      params["pageSize"] = pageSize1;
    }

    return params;
  }

  const handleViewOrder = (id) => {
    var data = orders.orders.filter((item) => {
      return item.id === id;
    });
    setOrder(data);

    setShowModalView(true);
  }

  const fetchDataToDownload = () => {
    axios.get("http://springboot-api-2-env.eba-nyur729x.us-east-1.elasticbeanstalk.com/api/csv/download")
      .then(({ data }) => {
        setDownloadedData(data);
        setTimeout(() => {
          csvDownloadRef.current.link.click();

        }, 500);
      })
      .catch((error) => alert("Error Message"))
  }

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrder(id));
  }





  const actionColumn = [
    { field: "id", headerName: "Order ID", width: 70 },
    {
      field: "username",
      headerName: "Customer",
      width: 230
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      type: "date",
      width: 200,
    },
    {
      field: "state",
      headerName: "State",
      type: "number",
      width: 150,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      type: "number",
      width: 150
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">

            <div className="btn btn-success" onClick={() => handleViewOrder(params.row.id)}>View</div>
            <div
              className="btn btn-danger"
              onClick={() => deleteOrderHandler(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    }
  ];


  useEffect(() => {

    if (isDeleted) {
      alert.success("Order Deleted Successfully");
      // history.push("/admin/dashboard");
      navigate("/orders");
      dispatch({ type: DELETE_ORDER_RESET });
    }


    const params = getRequestParams(page, pageSize);
    dispatch(getAllOrder(params));
  }, [page, pageSize, isDeleted, dispatch]);

  var rowCount1 = 0;
  if (orders.totalItems) {
    rowCount1 = orders.totalItems;
  }

  const rows = [];


  orders.orders &&
    orders.orders.forEach((item) => {
      rows.push({
        id: item.id,
        username: item.username,
        orderDate: item.createdAt,
        state: item.status,
        totalPrice: "$" +item.totalPrice,

      })
    });



  return (
    <>
      {showModalView && <ViewOrder showModal={setShowModalView} data={order} />}
      <div className="datatable">
        <div className="datatableTitle">
         
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchOutlinedIcon />
            {/* <CSVLink data={downloadedData} headers={headers} filename="parents.csv">
              <button className="btn btn-primary mb-2">Export</button>
            </CSVLink> */}
            
          </div>
          <CSVLink
            headers={headers}
            data={downloadedData}
            filename="parents.csv"
            className="hidden"
            ref={csvDownloadRef}
            target="_blank"
          >
            <button
              className="btn btn-primary mb-2"
              onClick={fetchDataToDownload}
              style={{ marginLeft: "5px" }}
            >
              Export Async
            </button>
          </CSVLink>
          <Link to="/products/newProduct" className="link">
            Add New
          </Link>
        </div>
        <DataGrid
          className="datagrid"
          rowCount={rowCount1}
          rows={rows}
          paginationMode="server"
          columns={actionColumn}
          pagination
          pageSize={pageSize}
          page={page - 1}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    </>
  );
};

export default OrderDataTable;