import axios from "axios";
import { GET_ALL_APPAREL_CATEGORY_FAIL, GET_ALL_APPAREL_CATEGORY_REQUEST, GET_ALL_APPAREL_CATEGORY_SUCCESS, NEW_APPAREL_CATEGORY_FAIL, NEW_APPAREL_CATEGORY_REQUEST, NEW_APPAREL_CATEGORY_SUCCESS, NEW_APPAREL_IMAGE_FAIL, NEW_APPAREL_IMAGE_REQUEST, NEW_BRAND_CATEGORY_FAIL, NEW_BRAND_CATEGORY_REQUEST, NEW_BRAND_CATEGORY_SUCCESS, NEW_BRAND_IMAGE_FAIL, NEW_BRAND_IMAGE_REQUEST, NEW_BRAND_IMAGE_SUCCESS, NEW_GENDER_CATEGORY_FAIL, NEW_GENDER_CATEGORY_REQUEST, NEW_GENDER_CATEGORY_SUCCESS } from "../constants/categoryConstant";


const API_URL = "https://kaitoshop.io.vn/api";
// const API_URL = "http://localhost:8083/api";

export const createApparelCategory = (apparel) => async (dispatch) => {
    try {
        dispatch({ type: NEW_APPAREL_CATEGORY_REQUEST });

        const { data } = await axios.post(
            API_URL + "/apparel/addApparelCategory",
            apparel
        );

        dispatch({
            type: NEW_APPAREL_CATEGORY_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: NEW_APPAREL_CATEGORY_FAIL,
            payload: error.response.data.message
        });
    }
}

// export const getAllApparelCategory = (params) => async dispatch => {
//     try {
//         dispatch({ type: GET_ALL_APPAREL_CATEGORY_REQUEST });

//         const { data } = await axios.get(
//             API_URL + "/apparel/getAllApparelCategory", 
//             {params}
//         );

//         dispatch({
//             type: GET_ALL_APPAREL_CATEGORY_SUCCESS,
//             payload: data
//         })
//     } catch(error) {
//         dispatch({
//             type: GET_ALL_APPAREL_CATEGORY_FAIL,
//             payload: error
//         });
//     }
// }

export const createApparelImage = (apparel) => async dispatch => {
    try {
        dispatch({ type: NEW_APPAREL_IMAGE_REQUEST });

        const { data } = await axios.post(
            API_URL + "/apparel/addApparelImages",
            apparel
        );
        dispatch({
            type: NEW_APPAREL_IMAGE_REQUEST,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: NEW_APPAREL_IMAGE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const createBrandCategory = (brand) => async dispatch => {
    try {
        dispatch({ type: NEW_BRAND_CATEGORY_REQUEST });

        const {data} = await axios.post(
            API_URL + "/brand/addBrandCategory",
            brand
        );

        dispatch({
            type: NEW_BRAND_CATEGORY_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: NEW_BRAND_CATEGORY_FAIL,
            payload: error.response.data.message
        });
    }
}

export const createBrandImage = (brand) => async dispatch => {
    try {
        dispatch({ type: NEW_BRAND_IMAGE_REQUEST });

        const {data} = await axios.post(
            API_URL + "/brand/addBrandImages",
            brand
        ) 

        dispatch({
            type: NEW_BRAND_IMAGE_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: NEW_BRAND_IMAGE_FAIL,
            payload: error.response.data.message
        });
    }
}

export const createGenderCategory = (brand) => async dispatch => {
    try {
        dispatch({ type: NEW_GENDER_CATEGORY_REQUEST });

        const {data} = await axios.post(
            API_URL + "/gender/addGender",
            brand
        );

        dispatch({
            type: NEW_GENDER_CATEGORY_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: NEW_GENDER_CATEGORY_FAIL,
            payload: error.response.data.message
        });
    }
}
