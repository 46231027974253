import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { clearErrors, createProduct, getAllCategories, getProductById } from "../../actions/productAction";

const EditProduct = ({ title }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const { product } = useSelector((state) => state.getProductDetailReducer);
  const alert = useAlert();
  const navigate = useNavigate();
  // const { loading, error, success } = useSelector((state) => state.productReducer);
  const { allCategories } = useSelector(state => state.getAllCategoryReducer);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [apparel, setApparel] = useState("");
  const [gender, setGender] = useState("");
  const [brand, setBrand] = useState("");
  const [stock, setStock] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");



  useEffect(() => {
    // if (error) {
    //   alert.error(error);
    //   dispatch(clearErrors);
    // }

    // if (success) {
    //   alert.success("Product Created Successfully");
    //   navigate("/");
    //   dispatch({ type: NEW_PRODUCT_RESET });
    // }
    dispatch(getAllCategories());
    dispatch(getProductById(id));



  }, [dispatch]);






  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("description", description);
    myForm.set("stock", stock);
    myForm.set("apparelName", apparel);
    myForm.set("genderName", gender);
    myForm.set("brandName", brand);

    myForm.append("images", file, file.name);
    dispatch(createProduct(myForm));
  }



  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  }

  const apparelList = [];

  allCategories.apparels &&
    allCategories.apparels.forEach((item) => {
      apparelList.push({
        id: item.id,
        type: item.type
      });
    });


  const brandList = [];

  allCategories.brands &&
    allCategories.brands.forEach((item) => {
      brandList.push({
        id: item.id,
        type: item.type
      });
    });

  const genderList = [];

  allCategories.genders &&
    allCategories.genders.forEach((item) => {
      genderList.push({
        id: item.id,
        type: item.type
      });
    });

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <label htmlFor="file">
              <img
                src={product.imageURL}
                alt=""
                className="icon"
              />
            </label>
          </div>
          <div className="right">
            <form
              className="addProductForm"
              encType="multipart/form-data"
              onSubmit={createProductSubmitHandler}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                  multiple
                />
              </div>

              <div className="formInput">
                <label>Product Name</label>
                <input
                  type="text"
                  placeholder={product.name}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Price</label>
                <input
                  type="number"
                  placeholder={product.price}
                  value={price}
                  required
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Description</label>
                <input
                  type="text"
                  placeholder={product.description}
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="formInput">
                <label>Stock</label>
                <input
                  type="number"
                  placeholder={product.availableQuantity}
                  value={stock}
                  required
                  onChange={(e) => setStock(e.target.value)}
                />
              </div>



              <div className="formInput">
                <select name={apparelList[product.apparel_id]} onChange={(e) => setApparel(e.target.value)}>
                  {apparelList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <select name={brandList[product.brand_id]} onChange={(e) => setBrand(e.target.value)}>
                  {brandList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <select name={genderList[product.gender_id]} onChange={(e) => setGender(e.target.value)}>
                  {genderList.map((cate) => (
                    <option key={cate.id} value={cate.type}>
                      {cate.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <label>Active</label>
                <select name="active" id="active">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <button type="submit">Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
