import "./orderlist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import OrderDataTable from "../../components/datatable/OrderDataTable"

const OrderList = (socket) => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar socket={socket}/>
        <OrderDataTable />
      </div>
    </div>
  )
}

export default OrderList;