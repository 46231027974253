import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SIGN_IN_ERROR } from "../../constants/authConstant";
import { signIn } from "../../actions/authAction";


const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(signIn(userName, password));
  }

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">Lama Chat</span>
        <span className="title">Login</span>
        <form className="form" onSubmit={loginSubmit} >
          <input className="input"
            type="text" placeholder="email"
            onChange={(e) => setUserName(e.target.value)} />
          <input className="input"
            type="password" placeholder="password"
            onChange={(e) => setPassword(e.target.value)} />
          <button className="button">Sign in</button>
        </form>
        <p>You don't have an account? <Link to="/register">Register</Link></p>
      </div>
    </div>
  );
};

export default Login;