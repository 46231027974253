export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";

export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";


export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";

export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";


export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAIL = "GET_ALL_CATEGORY_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAIL = "GET_ALL_PRODUCT_FAIL";
export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";

export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL";
export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST"

export const GET_COUNT_ALL_SUCCESS = "GET_COUNT_ALL_SUCCESS";
export const GET_COUNT_ALL_FAIL = "GET_COUNT_ALL_FAIL";
export const GET_COUNT_ALL_REQUEST = "GET_COUNT_ALL_REQUEST";

export const GET_DAILY_REVENUE_SUCCESS = "GET_DAILY_REVENUE_SUCCESS";
export const GET_DAILY_REVENUE_FAIL = "GET_DAILY_REVENUE_FAIL";
export const GET_DAILY_REVENUE_REQUEST = "GET_DAILY_REVENUE_REQUEST";

export const GET_MONTH_REVENUE_SUCCESS = "GET_MONTH_REVENUE_SUCCESS";
export const GET_MONTH_REVENUE_FAIL = "GET_MONTH_REVENUE_FAIL";
export const GET_MONTH_REVENUE_REQUEST = "GET_MONTH_REVENUE_REQUEST";

export const GET_6_LAST_MONTH_REVENUE_SUCCESS = "GET_6_LAST_MONTH_REVENUE_SUCCESS";
export const GET_6_LAST_MONTH_REVENUE_FAIL = "GET_6_LAST_MONTH_REVENUE_FAIL";
export const GET_6_LAST_MONTH_REVENUE_REQUEST = "GET_6_LAST_MONTH_REVENUE_REQUEST";


