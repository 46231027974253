import axios from "axios";
import { CLEAR_ERRORS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, 
    DELETE_PRODUCT_SUCCESS, GET_ALL_CATEGORY_FAIL, GET_ALL_CATEGORY_REQUEST, GET_ALL_CATEGORY_SUCCESS, GET_ALL_PRODUCT_FAIL, GET_ALL_PRODUCT_REQUEST, 
    GET_ALL_PRODUCT_SUCCESS, GET_PRODUCT_DETAIL_FAIL, GET_PRODUCT_DETAIL_REQUEST, GET_PRODUCT_DETAIL_SUCCESS, NEW_PRODUCT_FAIL, NEW_PRODUCT_REQUEST, NEW_PRODUCT_SUCCESS,
     UPDATE_PRODUCT_FAIL,
     UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS } from "../constants/type";

const API_URL = "https://kaitoshop.io.vn/commondata";
// const API_URL = "http://localhost:8083/commondata";

export const createProduct = (productData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PRODUCT_REQUEST });

        const { data } = await axios.post(
            API_URL + "/add",
            productData
        );

        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response.data.message,
        });
    }
}

export const updateProduct = (productData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_REQUEST });

        const { data } = await axios.post(
            API_URL + "/update",
            productData
        )

        dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: data.success,
        })
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: null,
        });
    }
}

export const getAllCategories = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CATEGORY_REQUEST });

        const { data } = await axios.get(API_URL + "/getAllCategories");


        dispatch({
            type: GET_ALL_CATEGORY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const deleteProduct = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PRODUCT_REQUEST });

        const { data } = await axios.delete(
            API_URL + "/deleteProduct/" + id,
        );

        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data.success
        })
    } catch (error) {
        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error.response.data.message
        });
    }
}

export const getAllProduct = (params) => async (dispatch) => {
    try {
        dispatch({type: GET_ALL_PRODUCT_REQUEST});

        const {data} = await axios.get(
            API_URL + "/getAllProducts",
            {params}
        );

        
        dispatch({
            type: GET_ALL_PRODUCT_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_ALL_CATEGORY_FAIL,
            payload: null
        });
    }
}

export const getProductById = (productId) => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCT_DETAIL_REQUEST });

        const { data } = await axios.get(
            API_URL + "/detail/" + productId,
        );

        dispatch({
            type: GET_PRODUCT_DETAIL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_DETAIL_FAIL,
            payload: error.response.data.message
        });
    }
}


export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };

