import {GET_6_LAST_MONTH_REVENUE_FAIL, GET_6_LAST_MONTH_REVENUE_REQUEST, GET_6_LAST_MONTH_REVENUE_SUCCESS, GET_COUNT_ALL_FAIL, GET_COUNT_ALL_REQUEST, GET_COUNT_ALL_SUCCESS, GET_DAILY_REVENUE_FAIL, 
    GET_DAILY_REVENUE_REQUEST, GET_DAILY_REVENUE_SUCCESS, GET_MONTH_REVENUE_FAIL, 
    GET_MONTH_REVENUE_REQUEST, GET_MONTH_REVENUE_SUCCESS} from "../constants/type";

import axios from "axios";

const API_URL = "https://kaitoshop.io.vn/api/calculate";
// const API_URL = "http://localhost:8083/api/calculate";

export const getCountAll = () => async dispatch => {
    try {
        dispatch({ type: GET_COUNT_ALL_REQUEST });

        const {data} = await axios.get(
            API_URL + "/getCount"
        );

        dispatch({
            type: GET_COUNT_ALL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_COUNT_ALL_FAIL,
            payload: null
        })
    }
}

export const getDailyRevenue = () => async dispatch => {
    try {
        dispatch({ type: GET_DAILY_REVENUE_REQUEST });

        const {data} = await axios.get(
            API_URL + "/getDailyRevenue"
        );

        dispatch({
            type: GET_DAILY_REVENUE_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_DAILY_REVENUE_FAIL,
            payload: null
        })
    }
}

export const getMonthRevenue = () => async dispatch => {
    try {
        dispatch({ type: GET_MONTH_REVENUE_REQUEST });

        const {data} = await axios.get(
            API_URL + "/getMonthRevenue"
        );

        dispatch({
            type: GET_MONTH_REVENUE_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: GET_MONTH_REVENUE_FAIL,
            payload: null
        })
    }
}

export const get6LastMonthRevenue = () => async dispatch => {
    try {
        dispatch({ type: GET_6_LAST_MONTH_REVENUE_REQUEST });

        const {data} = await axios.get(
            API_URL + "/getLast6Month"
        );

        dispatch({
            type: GET_6_LAST_MONTH_REVENUE_SUCCESS,
            payload: data
        });

    } catch(error) {
        dispatch({
            type: GET_6_LAST_MONTH_REVENUE_FAIL,
            payload: null
        });
    }
}