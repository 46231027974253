import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { getAllProduct, clearErrors, deleteProduct } from "../../actions/productAction"
import React, { Fragment, useEffect } from "react";
import "./datatable.scss";
import { DELETE_PRODUCT_RESET } from "../../constants/type";
import axios from "axios";



const ProductDataTable = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { products } = useSelector(state => state.getAllProductReducer);
  const { isDeleted } = useSelector(state => state.productReducer);
  const [page, setPage] = useState(2);
  const [pageSize, setPageSize] = useState(25);
  const [dataProduct, setDataProduct] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  // const API_URL = "http://localhost:8083/commondata";

  const API_URL = "https://kaitoshop.io.vn/commondata";

  const[rows, setDataRow] = useState([]);
  const[rowCount, setRowCount] = useState(0);
  const getRequestParams = (page1, pageSize1) => {
    let params = {};
    if (page1) {
      params["page"] = page1 - 1;
    }
    if (pageSize1) {
      params["pageSize"] = pageSize1;
    }

    return params;
  }

  const deleteProductHandler = (id) => {
    dispatch(deleteProduct(id));
  };


  //   const columns= [
  //     {
  //         name:"Sr.No",
  //         selector:(row)=>row.id,
  //     },
  //     {
  //         name:"Name",
  //         selector:(row)=>row.name,
  //     },
  //     {
  //         name:"Category",
  //         selector:(row)=>row.availableQuantity,
  //     },
  //     {
  //         name:"Price",
  //         selector:(row)=>row.price,
  //     },
  //     {
  //         name:"Image",
  //         selector:(row)=><img  height ={70} width={80} src={ row.imageURL}/>,
  //     },
  //     {
  //         name:"Action",
  //         cell:(row)=>(
  //             <button className="btn btn-danger" onClick={()=>deleteProductHandler(row.id)}>Delete</button>
  //         )
  //     }

  // ];



  const actionColumn = [
    { field: "id", headerName: "Product ID", width: 70 },
    {
      field: "imageURL",
      headerName: "imageURL",
      width: 120,
      renderCell: (params) => (

        <div className="cellWithImg">
          <img className="cellImg" src={params.row.imageURL} alt="avatar" />

        </div>

      )
    },
    {
      field: "name",
      headerName: "Product Name",
      width: 230
    },
    {
      field: "availableQuantity",
      headerName: "Stock",
      type: "number",
      width: 100,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 150
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/products/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="btn btn-success">View</div>
            </Link>


            <Link to={`/products/editproduct/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="btn btn-success">Edit</div>
            </Link>

            <div
              className="btn btn-danger"
              onClick={() => deleteProductHandler(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];



  const getProduct = (params) => {
    axios.get(
      API_URL + "/getAllProducts",
      { params }
    ).then((res) => {
      setDataRow(res.data.products)
      setRowCount(res.data.totalItems)
    })
      .catch(err => {
        console.log(err);
      });

    
   
  }

  const getSearchResult = async (search) => {
    axios.get(
      API_URL + "/search?query=" + search
    ).then((res) => setDataRow(res.data))
      .catch(err => {
        console.log(err);
      })
    
  }



  useEffect(() => {

    if (isDeleted) {
      alert.success("Product Deleted Successfully");
      navigate("/products")
      dispatch({ type: DELETE_PRODUCT_RESET });
    }
    const params = getRequestParams(page, pageSize);

    if (searchKey !== null) {
      getSearchResult(searchKey);
     
    } else {
      getProduct(params);
      
     
      
    }

    
  }, [page, pageSize, isDeleted, searchKey]);


  return (
    <div className="datatable">
      <div className="datatableTitle">
        List Of Products
        <Link to="/products/newProduct" className="btn btn-success">
          Add New
        </Link>
        <input type="text"
          className="w-25 form-control"
          placeholder="Search..."
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}

        />
      </div>
      <DataGrid
        className="datagrid"
        rowCount={rowCount}
        rows={rows}
        paginationMode="server"
        columns={actionColumn}
        pagination
        pageSize={pageSize}
        page={page}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPage(newPage)}
      />


    </div>
  );
};

export default ProductDataTable;
