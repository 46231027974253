import "./userlist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import UserDataTable from "../../components/datatable/UserDataTable"

const UserList = (socket) => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
       
        <UserDataTable />
      </div>
    </div>
  )
}

export default UserList;