import Home from "./pages/home/Home";
import Login from "./components/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import SingleProduct from "./pages/single/SingleProduct";
import OrderList from "./pages/order/OrderList";
import UserList from "./pages/user/UserList";
import NewApparelCategory from "./pages/category/NewApparelCategory";
import NewApparelImage from "./pages/category/NewApparelImage";
import NewBrandCategory from "./pages/category/NewBrandCategory";
import NewGenderCategory from "./pages/category/NewGenderCategory";
import NewBrandImage from "./pages/category/NewBrandImage";
import "./App.css";

import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import NewProduct from "./pages/product/NewProduct";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "./actions/message";
import { logout } from "./actions/userAction";
import EditProduct from './pages/edit/EditProduct'
import Profile from "./components/user/Profile";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  // const [socket, setSocket] = useState(null);
  // const [user, setUser] = useState("");


  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" />
    }

    return children;
  }
  // const location = useLocation();

  // useEffect(() => {
  //   if(["/login"].includes(location.pathname)) {
  //     dispatch(clearMessage());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   setSocket(socket1);
  // }, []);




  // const logOut = useCallback(() => {
  //   dispatch(logOut());
  // },[dispatch]);



  return (
    <div className={darkMode ? "app dark" : "app"}>
      <div className="main">
      <BrowserRouter>
        <Routes>

          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="apparelCategory" element={
              <ProtectedRoute>
                <NewApparelCategory />
              </ProtectedRoute>} />
            <Route path="apparelImage" element={<NewApparelImage />} />
            <Route path="brandImage" element={<NewBrandImage />} />
            <Route path="brandCategory" element={<NewBrandCategory />} />
            <Route path="genderCategory" element={<NewGenderCategory />} />
            <Route path="profile" element={<Profile />} />
            <Route index element={ <Home /> } />
         
            <Route path="users">
              <Route index element={<UserList />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<List />} />
              <Route path=":id" element={<SingleProduct />} />
              <Route
                path="newProduct"
                element={<New inputs={userInputs} title="Add New Product" />}
              />
              <Route
                path="editproduct/:id"
                element={<EditProduct />}
              />
            </Route>

            <Route path="orders">
              <Route index element={<OrderList />} />
              <Route path=":id" element={<SingleProduct />} />
            </Route>
          </Route>



        </Routes>

      </BrowserRouter>
      </div>

    </div>
  );
}

export default App;
