import { HANDLE_SIGN_IN, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_OUT, RESET_SIGN_IN_ERROR } from "../constants/authConstant";
import _ from "lodash";

export const signInReducer = (state = {isSignedIn: null, timeStamp: null, firstName: null},action) => {
    switch(action.type) {
        case HANDLE_SIGN_IN:
            return {
                ...state, isSignedIn: true, tokenId: action.payload.jwt,
                errorMsg: null, firstName: action.payload.firstName, timeStamp: Date.now()
            };
        case HANDLE_SIGN_IN_ERROR:
            return {
                ...state, isSignedIn: false, errorMsg: action.payload,
                timeStamp: Date.now()
            };
        case RESET_SIGN_IN_ERROR:
            return {
                ...state, errorMsg: null
            };
        case HANDLE_SIGN_OUT:
            _.omit(state, 'tokenId', 'errorMsg');
            return {
                ...state, isSignedIn: false, firstName: null
            };
        default:
            return state;

    }
}

 