import axios from "axios";
import { CLEAR_ERRORS, NEW_ORDER_SUCCESS } from "../constants/orderConstant";
import {DELETE_USER_FAIL, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, GET_ALL_USER_FAIL, GET_ALL_USER_REQUEST, GET_ALL_USER_SUCCESS, GET_USER_DETAIL_FAIL, GET_USER_DETAIL_REQUEST, GET_USER_DETAIL_SUCCESS, NEW_USER_FAIL, NEW_USER_REQUEST, NEW_USER_SUCCESS } from "../constants/userConstant";
import {Base64} from 'js-base64';
import {AUTH_DETAILS_COOKIE, HANDLE_SIGN_IN, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_OUT} from "../constants/http_error_code";
import Cookies from 'js-cookie';
import AuthService from "../service/auth.service"
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE } from "../constants/authConstant";

const API_URL = "https://kaitoshop.io.vn/api/auth";
// const API_URL = "http://localhost:8083/api/auth";


export const login = (username, password) => (dispatch) => {
    AuthService.login(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data}
            });

            return Promise.resolve();
        },
        (error) => {
            const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message
            });

            return Promise.reject();
        }
    );
};

export const logout = (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT
    });
};

export const updateUser = (data) => async (dispatch) => {   
    try {
        dispatch({ type: NEW_USER_REQUEST });

        const {data} = await axios.put(
            API_URL + "/update",
            data
        );

        dispatch({
            type: NEW_USER_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: NEW_USER_FAIL,
            payload: null
        })
    }
}

export const deleteUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_USER_REQUEST });

        const {data} = await axios.delete(
            API_URL + "/delete/" + id
        );

        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: null
        })
    }
}

export const getAllUsers = (params) => async dispatch => {
    try {
        dispatch({ type: GET_ALL_USER_REQUEST });

        const {data} = await axios.get(
            API_URL + "/allUser",
            {params}
        );

        dispatch({
            type: GET_ALL_USER_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_ALL_USER_FAIL,
            payload: null
        })
    }
}

export const getUserById = (id) => async dispatch => {
    try {
        dispatch({ type: GET_USER_DETAIL_REQUEST });
        
        const { data } = await axios.get(
            API_URL + "/" + id
        );

        dispatch({
            type: GET_USER_DETAIL_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_USER_DETAIL_FAIL,
            payload: null
        })
    }
}

export const clearErrors = () => async dispatch => {
    dispatch({ 
        type: CLEAR_ERRORS
    })
}