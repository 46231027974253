import { DELETE_ORDER_FAIL, DELETE_ORDER_REQUEST, DELETE_ORDER_RESET, DELETE_ORDER_SUCCESS, GET_ALL_ORDER_FAIL, GET_ALL_ORDER_REQUEST, GET_ALL_ORDER_SUCCESS, GET_ORDER_DETAIL_FAIL, GET_ORDER_DETAIL_REQUEST, GET_ORDER_DETAIL_SUCCESS, GET_ORDER_ITEM_DETAIL_FAIL, GET_ORDER_ITEM_DETAIL_SUCCESS, UPDATE_ORDER_FAIL, UPDATE_ORDER_REQUEST, UPDATE_ORDER_RESET, UPDATE_ORDER_SUCCESS } from "../constants/orderConstant";
import { CLEAR_ERRORS } from "../constants/type";


export const getAllOrderReducer = (state = { orders: {} }, action) => {
    switch (action.type) {
        case GET_ALL_ORDER_REQUEST:
            return {
                loading: true,
                orders: {}
            };
        case GET_ALL_ORDER_SUCCESS:
            return {
                orders: action.payload,
            };
        case GET_ALL_ORDER_FAIL:
            return {
                loading: false,
                error: null
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}

export const getOrderDetailReducer = (state = { order: {} }, action) => {
    switch (action.type) {
        case GET_ORDER_DETAIL_REQUEST:
            return {
                loading: true,
                order: {}
            };
        case GET_ORDER_DETAIL_SUCCESS:
            return {
                order: action.payload
            };
        case GET_ORDER_DETAIL_FAIL:
            return {
                loading: false,
                error: null
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;

    }
}

export const getOrderItemDetailReducer = (state = { orderItem: [] }, action) => {
    switch (action.type) {
        case GET_ORDER_DETAIL_REQUEST:
            return {
                loading: true,
                orderItem: []
            };
        case GET_ORDER_ITEM_DETAIL_SUCCESS:
            return {
                orderItem: action.payload
            };
        case GET_ORDER_ITEM_DETAIL_FAIL:
            return {
                loading: false,
                error: null
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;

    }
}

export const changeOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_ORDER_REQUEST:
        case UPDATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true,
            };

        case DELETE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true,
            };
        case DELETE_ORDER_FAIL:
        case UPDATE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_ORDER_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case UPDATE_ORDER_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}