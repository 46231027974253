
import { HANDLE_SIGN_IN, HANDLE_SIGN_IN_ERROR, HANDLE_SIGN_OUT, HANDLE_SIGN_UP_ERROR, RESET_SIGN_IN_ERROR, RESET_SIGN_UP } from "../constants/http_error_code";
import { UPDATE_ORDER_FAIL } from "../constants/orderConstant";
import { CLEAR_ERRORS, DELETE_USER_FAIL, DELETE_USER_REQUEST, DELETE_USER_RESET, DELETE_USER_SUCCESS, GET_ALL_USER_FAIL, GET_ALL_USER_REQUEST, GET_ALL_USER_SUCCESS, GET_USER_DETAIL_FAIL, GET_USER_DETAIL_REQUEST, GET_USER_DETAIL_SUCCESS, UPDATE_USER_REQUEST, UPDATE_USER_RESET, UPDATE_USER_SUCCESS } from "../constants/userConstant";
import _ from "lodash";
export const getAllUserReducer = (state = { users: {} }, action) => {
    switch (action.type) {
        case GET_ALL_USER_REQUEST:
            return {
                loading: true,
                users: {}
            }
        case GET_ALL_USER_SUCCESS:
            return {
                users: action.payload
            }
        case GET_ALL_USER_FAIL:
            return {
                loading: false,
                error: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const getUserDetailReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case GET_USER_DETAIL_REQUEST:
            return {
                loading: true,
                user: {}
            }
        case GET_USER_DETAIL_SUCCESS:
            return {
                loading: false,
                user: action.payload
            }
        case GET_USER_DETAIL_FAIL:
            return {
                loading: false,
                error: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
        default:
            return state;
    }
}

export const changeUserReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_USER_REQUEST:
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }
        case DELETE_USER_FAIL:
        case UPDATE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: null
            }
        case DELETE_USER_RESET:
            return {
                ...state,
                isDeleted: false,
            }
        case UPDATE_USER_RESET:
            return {
                ...state,
                isUpdated: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;

    }
}

export const signInReducer = (state = {isSignedIn: null, timestamp: null, firstName: null}, action) => {
    switch(action.type) {
        case HANDLE_SIGN_IN:
            return {
                ...state, isSignedIn: true, tokenId: action.payload.jwt,
                errorMsg: null, firstName: action.payload.firstName, timestamp: Date.now()
            };
        case HANDLE_SIGN_IN_ERROR:
            return {
                ...state, isSignedIn: false, errorMsg: action.payload,
                timestamp: Date.now()
            };
        case RESET_SIGN_IN_ERROR:
            return {
                ...state, errorMsg: null
            };
        case HANDLE_SIGN_OUT:
            _.omit(state, 'tokenId','errorMsg');
            return {
                ...state, isSignedIn: false, firstName: null
            };
        default:
            return state;
    }
};

export const signUpReducer = (state = {errorMsg: null, timestamp: null}, action) => {
    switch(action.type) {
        case HANDLE_SIGN_UP_ERROR:
            return {...state, errorMsg: action.payload, timestamp: Date.now()};
        case RESET_SIGN_UP:
            return {account_status: null, errorMsg: null};
        default:
            return state;
    }
}    
