export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const HANDLE_SIGN_IN= "HANDLE_SIGN_IN";
export const HANDLE_SIGN_OUT= "HANDLE_SIGN_OUT";
export const HANDLE_SIGN_IN_ERROR= "HANDLE_SIGN_IN_ERROR";
export const RESET_SIGN_IN_ERROR = "RESET_SIGN_IN_ERROR";
export const AUTH_DETAILS_COOKIE = "AUTH_DETAILS_COOKIE";