import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
// import {ge} from "../../actions/productAction";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import React, { Fragment, useEffect } from "react";
import {getProductById} from "../../actions/productAction";
import { useLocation } from "react-router-dom";




const SingleProduct = (socket) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const {product} = useSelector(state => state.getProductDetailReducer);

    useEffect(() => {
       dispatch(getProductById(id));
    }, [dispatch]);


 


    return (
      <div className="single">
        <Sidebar />
        <div className="singleContainer">
          <Navbar socket={socket} />
          <div className="top">
            <div className="left">
             
              <h1 className="title">Information</h1>
              <div className="item">
                <img
                  src={product.imageURL}
                  alt=""
                  className="itemImg"
                />
                <div className="details">
                  <h1 className="itemTitle">{product.name}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Price:</span>
                    <span className="itemValue">{product.price} $</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Stock:</span>
                    <span className="itemValue">{product.availableQuantity}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Created Date:</span>
                    <span className="itemValue">
                      {product.created_at}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Ratings:</span>
                    <span className="itemValue">{product.ratings}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
            </div>
          </div>
          <div className="bottom">
          <h1 className="title">Last Transactions</h1>
            <List/>
          </div>
        </div>
      </div>
    );
  };
  
  export default SingleProduct;