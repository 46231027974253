import {
    GET_6_LAST_MONTH_REVENUE_FAIL,
    GET_6_LAST_MONTH_REVENUE_REQUEST,
    GET_6_LAST_MONTH_REVENUE_SUCCESS,
    GET_COUNT_ALL_FAIL, GET_COUNT_ALL_REQUEST, GET_COUNT_ALL_SUCCESS, GET_DAILY_REVENUE_FAIL,
    GET_DAILY_REVENUE_REQUEST, GET_DAILY_REVENUE_SUCCESS, GET_MONTH_REVENUE_FAIL,
    GET_MONTH_REVENUE_REQUEST, GET_MONTH_REVENUE_SUCCESS
} from "../constants/type";

export const getCountAllReducer = (state = { count: {} }, action) => {
    switch (action.type) {
        case GET_COUNT_ALL_REQUEST:
            return {
                loading: true,
                count: {}
            };
        case GET_COUNT_ALL_SUCCESS:
            return {
                count: action.payload
            };
        case GET_COUNT_ALL_FAIL:
            return {
                loading: false,
                error: null
            }
        default:
            return state;
    }
}

export const getDailyRevenueReducer = (state = { dailyRevenue: {} }, action) => {
    switch (action.type) {
        case GET_DAILY_REVENUE_REQUEST:
            return {
                loading: true,
                dailyRevenue: {}
            };
        case GET_DAILY_REVENUE_SUCCESS:
            return {
                dailyRevenue: action.payload
            };
        case GET_DAILY_REVENUE_FAIL:
            return {
                loading: false,
                error: null
            }
        default:
            return state;
    }
}

export const getMonthRevenueReducer = (state = { monthRevenue: {} }, action) => {
    switch (action.type) {
        case GET_MONTH_REVENUE_REQUEST:
            return {
                loading: true,
                monthRevenue: {}
            };
        case GET_MONTH_REVENUE_SUCCESS:
            return {
                monthRevenue: action.payload
            }
        case GET_MONTH_REVENUE_FAIL:
            return {
                loading: false,
                error: null
            }
        default:
            return state;
    }
}

export const get6LastMonthRevenueReducer = (state = { lastMonthRevenue: {} }, action) => {
    switch(action.type) {
        case GET_6_LAST_MONTH_REVENUE_REQUEST:
            return {
                loading: true,
                lastMonthRevenue: {}
            };
        case GET_6_LAST_MONTH_REVENUE_SUCCESS:
            return {
                lastMonthRevenue: action.payload
            }
        case GET_6_LAST_MONTH_REVENUE_FAIL:
            return {
                loading: false,
                error: null
            };
        default:
            return state;
    }
}