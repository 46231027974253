import styles from './ViewOrder.module.css';

function InfoOrder({ orderItems }) {
    return (
        <>
            <div className={`${styles.table} custom-scrollbars`}>
                <div className={styles.title}>
                    <h4>Order Information</h4>
                </div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <th>Product Name</th>
                            <th>Product Image</th>
                            <th>Price</th>
                            <th>Quantity</th>
                        </tr>
                        {orderItems.map((item) => {
                            // const pro_attr = JSON.parse(item.pro_attr);
                            return (
                                <tr key={item.id}>
                                    <td style={{ textAlign: 'center' }}>
                                        <span>{item.name}</span>
                                    </td>
                                    <td >
                                        <img
                                            src={`${item.imageUrl}`}
                                            alt="avatar"
                                            
                                        />
                                    </td>
                                    <td style={{ textAlign: 'center'}} >
                                        {item.price}
                                    </td>
                                    <td style={{ textAlign: 'center'}}>
                                        {item.quantity}
                                    </td>
                                </tr>

                            );
                        })}
                        <tr></tr>
                    </tbody>

                </table>
            </div>
        </>
    )
}

export default InfoOrder;