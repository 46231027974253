import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import React, { Fragment, useEffect } from "react";
import "./datatable.scss";
import { DELETE_PRODUCT_RESET } from "../../constants/type";
import { deleteUser, getAllUsers } from "../../actions/userAction";
import { DELETE_USER_REQUEST } from "../../constants/userConstant";


const UserDataTable = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const alert = useAlert();
    const { users } = useSelector(state => state.getAllUserReducer);
    const { isDeleted } = useSelector(state => state.changeUserReducer);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getRequestParams = (page, pageSize) => {
        let params = {};
        if (page) {
            params["page"] = page - 1;
        }
        if (pageSize) {
            params["pageSize"] = pageSize;
        }

        return params;
    }

    const deleteUserHandler = (id) => {
        dispatch(deleteUser(id));
    }

    const actionColumn = [
        { field: "id", headerName: "Product ID", width: 70 },
        {
            field: "name",
            headerName: "User Name",
            width: 230
        },
        {
            field: "created_date",
            headerName: "Created Date",
            width: 130
        },
        {
            field: "email",
            headerName: "Email",
            width: 230
        },
        {
            field: "role",
            headerName: "Role",
            width: 100
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link>
                            <div className="btn btn-success">View</div>
                        </Link>
                        <div
                             className="btn btn-danger"
                            onClick={() => deleteUserHandler(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (isDeleted) {
            alert.success("User has been deleted successfully");
            navigate("/users");
            dispatch({ type: DELETE_USER_REQUEST });
        }

        const params = getRequestParams(page, pageSize);
        dispatch(getAllUsers(params));
    }, [ pageSize, isDeleted, dispatch]);

    var rowCount = 0;
    if (users.totalItems) {
        rowCount = users.totalItems;
    }

    const rows = [];

    users.users &&
        users.users.forEach((item) => {
            rows.push({
                id: item.id,
                name: item.username,
                email: item.email,
                role: item.roles[0].name,
                created_date: item.createdAt
            })
        });

    return (
        <div className="datatable">
            <div className="datatableTitle">
                List Of Users
                <Link to="/products/newProduct" className="link">
                    Add New
                </Link>
            </div>
            <DataGrid
                className="datagrid"
                rowCount={rowCount}
                rows={rows}
                paginationMode="server"
                columns={actionColumn}
                pagination
                pageSize={pageSize}
                page={page}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) => setPage(newPage)}
            />
        </div>
    );
};

export default UserDataTable;