import styles from './ViewOrder.module.css';


function InfoMember({ data }) {
    return (
        <div className={styles.table}>
        <div className={styles.title}>
                <h4>Customer Information</h4>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td>Customer ID</td>
                        <td>{data[0].id}</td>
                    </tr>
                    <tr>
                        <td>Customer Name</td>
                        <td>{data[0].username}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{data[0].email}</td>
                    </tr>
                    <tr>
                        <td>Province/City</td>
                        <td>{data[0].city}</td>
                    </tr>
                    <tr>
                        <td>Created Date</td>
                        <td>{data[0].createdAt}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{data[0].address}</td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>{data[0].phoneNo}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

}

export default InfoMember;