import { useState, useEffect, useRef } from 'react';
import InfoMember from './InfoMember';
import InfoOrder from './InfoOrder';
import FadeIn from './FadeIn';
import { useSelector, useDispatch } from "react-redux";
import styles from './ViewOrder.module.css';
import { getOrderItemById } from "../../actions/orderAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartCircleXmark } from '@fortawesome/free-solid-svg-icons';



function ViewOrder({ data, showModal }) {
    const dispatch = useDispatch();
    const { orderItem } = useSelector(state => state.getOrderItemDetailReducer);
    const liRef = useRef();
    const [indexLi, setIndexLi] = useState(1);
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setLeft(liRef.current.offsetLeft);
        setWidth(liRef.current.offsetWidth);
    }, [indexLi]);

    useEffect(() => {
        dispatch(getOrderItemById(data[0].id));

    }, []);




    const changeIndexLi = (id) => {
        setIndexLi(id);
    }

    return (
        <FadeIn>
            <div className={styles.box}>
                <div className={styles.title}>
                    <h4>Order Information</h4>
                    <div className={styles.icon} >
                        {/* <FontAwesomeIcon
                        icon={faHeartCircleXmark}
                        className="open"
                        onClick={() => showModal(false)}
                    /> */}
                        <button className="close" onClick={() => showModal(false)}>
                            X
                        </button>

                    </div>
                </div>
                <div className={styles.nav_tab}>
                    <ul>
                        <li
                            ref={indexLi === 1 ? liRef : undefined}
                            className={indexLi === 1 ? styles.active : ''}
                            onClick={() => {
                                changeIndexLi(1)
                            }}
                        >
                            Customer Information
                        </li>
                        <li
                            ref={indexLi === 2 ? liRef : undefined}
                            className={indexLi === 2 ? styles.active : ''}
                            onClick={() => {
                                changeIndexLi(2)
                            }}
                        >
                            OrderItem Information
                        </li>
                        <div className={styles.line} style={{ left, width }}></div>
                    </ul>
                </div>
                <div className={`${styles.content} custom-scrollbars`}>
                    {indexLi === 1 && <InfoMember data={data} />}
                    {indexLi === 2 && <InfoOrder orderItems={orderItem} />}
                </div>
            </div>
        </FadeIn>
    )
}

export default ViewOrder;

