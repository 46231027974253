
import { useDispatch, useSelector } from "react-redux";
import "./newProduct.scss";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { clearErrors, getAllCategories } from "../../actions/productAction";
import { NEW_APPAREL_IMAGE_RESET } from "../../constants/categoryConstant";
import { createApparelImage } from "../../actions/categoryAction";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";


export default function NewApparelImage() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const navigate = useNavigate();
    const { loading, error, success } = useSelector((state) => state.createApprelImageReducer);
    const { allCategories } = useSelector((state) => state.getAllCategoryReducer);
    const [name, setName] = useState("");
    const [image, setImage] = useState([]);
    const [apparel, setApparel] = useState("");
    const [gender, setGender] = useState("");


    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (success) {
            alert.success("ApprelImage has been Created Successfully!");
            navigate("/");
            dispatch({ type: NEW_APPAREL_IMAGE_RESET });
        }

        dispatch(getAllCategories());
    }, [dispatch, error, success]);

    const createApparelImageSubmitHandler = (e) => {
        e.preventDefault();

        const myForm = new FormData();
        myForm.set("title", name);
        myForm.set("apparelName", apparel);
        myForm.set("genderName", gender);
        myForm.append("images", image, image.name);
        dispatch(createApparelImage(myForm));
    }

    var apparelList = [];
    allCategories.apparels &&
        allCategories.apparels.forEach((item) => {
            apparelList.push({
                id: item.id,
                type: item.type,
            });
        });

    var genderList = [];
    allCategories.genders &&
        allCategories.genders.forEach((item) => {
            genderList.push({
                id: item.id,
                type: item.type
            });
        });

    const onFileChange = (event) => {
        setImage(event.target.files[0]);
    }

    // console.log(apparelList);
    // console.log(genderList);

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <form
                    className="addProductForm"
                    encType="multipart/form-data"
                    onSubmit={createApparelImageSubmitHandler}>
                    <div className="addProductItem">
                        <label>Image</label>
                        <input
                            type="file"
                            id="file"
                            accept="image/*"
                            name="avatar"
                            onChange={onFileChange}
                            multiple
                        />
                    </div>
                    <div className="addProductItem">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Apple Airpods"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="addProductItem">
                        <select onChange={(e) => setApparel(e.target.value)}>
                            <option >Choose Apparel Category</option>
                            {apparelList.map((cate) => (
                                <option key={cate.id} value={cate.type}>
                                    {cate.type}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="addProductItem">
                        <select onChange={(e) => setGender(e.target.value)}>
                            <option >Choose Gender Category</option>
                            {genderList.map((cate) => (
                                <option key={cate.id} value={cate.type}>
                                    {cate.type}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="addProductItem">
                        <label>Active</label>
                        <select name="active" id="active">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                    <button className="addProductButton" type="submit">Create</button>
                </form>
            </div>
        </div>
    )
}