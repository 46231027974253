import React, { Fragment, useEffect } from "react";

import styles from './singleUser.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const Profile = () => {
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="bottom">


          <form  >
            <img
              src={
                "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <label>Username</label>
            <input type="text" name="username" value="username" disabled />
            <label>Email</label>
            <input type="email" name="email" value="email" disabled />

            <label>Phone</label>
            <input type="text" name="phone" value="0869508406" disabled />
            <label>Address</label>
            <textarea type="text" name="address" value="Tien Giang" disabled />
            <label>Is Admin?</label>
            <select name="isAdmin" id="isAdmin">
              <option value={true} >Yes</option>
              <option value={false} >No</option>
            </select>
            <label>Is Active?</label>
            <select name="isActive" id="isActive">
              <option value={true} >Yes</option>
              <option value={false} >No</option>
            </select>
            <button>Update</button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Profile;