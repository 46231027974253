import axios from "axios";
import {NEW_ORDER_FAIL, NEW_ORDER_REQUEST, NEW_ORDER_SUCCESS,
        DELETE_ORDER_REQUEST,
        DELETE_ORDER_SUCCESS,
        DELETE_ORDER_FAIL,
        GET_ALL_ORDER_REQUEST,
        GET_ALL_ORDER_SUCCESS,
        GET_ALL_ORDER_FAIL,
        GET_ORDER_DETAIL_REQUEST,
        GET_ORDER_DETAIL_SUCCESS,
        GET_ORDER_DETAIL_FAIL,
        CLEAR_ERRORS,
        GET_ORDER_ITEM_DETAIL_REQUEST,
        GET_ORDER_ITEM_DETAIL_FAIL,
        GET_ORDER_ITEM_DETAIL_SUCCESS} from "../constants/orderConstant";

const API_URL = "https://kaitoshop.io.vn/api/order";
// const API_URL = "http://localhost:8083/api/order";

export const updateOrder = (orderData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_ORDER_REQUEST });

        const {data} = await axios.put(
            API_URL + "/update",
            orderData
        );

        dispatch({
            type: NEW_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_ORDER_FAIL,
            payload: null
        })
    }
}

export const deleteOrder = (id) => async dispatch => {
    try {
        dispatch({ type: DELETE_ORDER_REQUEST });

        const { data } = await axios.delete(
            API_URL + "/delete/" + id
        );

        dispatch({
            type: DELETE_ORDER_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: DELETE_ORDER_FAIL,
            payload: null
        });
    }
}

export const getAllOrder = (params) => async (dispatch) => {
    try { 
        dispatch({ type: GET_ALL_ORDER_REQUEST });

        const {data} = await axios.get(
            API_URL + "/allOrders",
            {params}
        );

        dispatch({
            type: GET_ALL_ORDER_SUCCESS,
            payload: data
        })
    } catch(error) {
        dispatch({
            type: GET_ALL_ORDER_FAIL,
            payload: null
        })
    }
}

export const getOrderById = (id) => async dispatch => {
    try {
        dispatch({
            type: GET_ORDER_DETAIL_REQUEST
        });

        const { data } = await axios.get(
            API_URL + "/" + id
        );

        dispatch({
            type: GET_ORDER_DETAIL_SUCCESS,
            payload: data
        });
    } catch(error) {
        dispatch({
            type: GET_ORDER_DETAIL_FAIL,
            payload: null
        });
    }

}

export const getOrderItemById = (id) => async dispatch => {
    try {
        dispatch({ type: GET_ORDER_ITEM_DETAIL_REQUEST});

        const { data } = await axios.get(
            API_URL + "/orderItem/" + id 
        );

        dispatch({
            type: GET_ORDER_ITEM_DETAIL_SUCCESS,
            payload: data,
        })
    } catch(errror) {
        dispatch({
            type: GET_ORDER_ITEM_DETAIL_FAIL,
            payload: null
        })
    }
}

export const clearErrors = () => async dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
