import { useDispatch, useSelector } from "react-redux";
import "./newUser.scss";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { StarRateTwoTone } from "@material-ui/icons";
import { clearErrors } from "../../actions/productAction";
import { NEW_APPAREL_CATEGORY_RESET, NEW_BRAND_CATEGORY_RESET } from "../../constants/categoryConstant"
import { createBrandCategory } from "../../actions/categoryAction";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

export default function NewBrandCategory() {
    const dispatch = useDispatch();
    const alert = useAlert();
    const navigate = useNavigate();
    const { loading, error, success } = useSelector((state) => state.createBrandCategoryReducer);
    const [title, setTitle] = useState("");

    useEffect(() => {
        if(error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if(success) {
            alert.success("Brand Category has been Created Successfully");
            navigate("/");
            dispatch({ type: NEW_BRAND_CATEGORY_RESET });
        }
    }, [dispatch, alert, error, success]);

    const createBrandSubmitHandler = (e) => {
        e.preventDefault();
        const myForm = new FormData();
        myForm.set("type", title);

        dispatch(createBrandCategory(myForm));
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="bottom">
                    <form
                        encType="multipart/form-data"
                        onSubmit={createBrandSubmitHandler}
                    >
                        <div className="formInput">
                            <label>Brand Category Name</label>
                            <input type="text"
                                placeholder="Brand Category Name"
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                className="input"
                            />
                        </div>
                        <button className="newUserButton" type="submit">Create</button>
                    </form>
                </div>
            </div>
        </div>
    )

}