import { NEW_APPAREL_CATEGORY_FAIL, NEW_APPAREL_CATEGORY_REQUEST, NEW_APPAREL_CATEGORY_RESET, NEW_APPAREL_CATEGORY_SUCCESS, NEW_APPAREL_IMAGE_FAIL, NEW_APPAREL_IMAGE_REQUEST, NEW_APPAREL_IMAGE_RESET, NEW_APPAREL_IMAGE_SUCCESS, NEW_BRAND_CATEGORY_FAIL, NEW_BRAND_CATEGORY_REQUEST, NEW_BRAND_CATEGORY_RESET, NEW_BRAND_CATEGORY_SUCCESS, NEW_BRAND_IMAGE_FAIL, NEW_BRAND_IMAGE_REQUEST, NEW_BRAND_IMAGE_RESET, NEW_BRAND_IMAGE_SUCCESS, NEW_GENDER_CATEGORY_FAIL, NEW_GENDER_CATEGORY_REQUEST, NEW_GENDER_CATEGORY_RESET, NEW_GENDER_CATEGORY_SUCCESS } from "../constants/categoryConstant";
import { CLEAR_ERRORS } from "../constants/type";

export const createApparelCategoryReducer = (state = { apparelCategory: {} }, action) => {
    switch (action.type) {
        case NEW_APPAREL_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_APPAREL_CATEGORY_SUCCESS:
            return {
                loading: false,
                success: true,
                apparelCategory: action.payload
            }
        case NEW_APPAREL_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_APPAREL_CATEGORY_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}

export const createApprelImageReducer = (state = { apparelImage: {} }, action) => {
    switch (action.type) {
        case NEW_APPAREL_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_APPAREL_IMAGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                apparelImage: action.payload
            }
        case NEW_APPAREL_IMAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_APPAREL_IMAGE_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}

export const createBrandCategoryReducer = (state = { brandCategory: {} }, action) => {
    switch (action.type) {
        case NEW_BRAND_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_BRAND_CATEGORY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                brandCategory: action.payload
            }
        case NEW_BRAND_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_BRAND_CATEGORY_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}

export const createBrandImageReducer = (state = { brandImage: {} }, action) => {
    switch (action.type) {
        case NEW_BRAND_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_BRAND_IMAGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                brandImage: action.payload
            }
        case NEW_BRAND_IMAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_BRAND_IMAGE_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}


export const createGenderCategoryReducer = (state = { genderCategory: {} }, action) => {
    switch (action.type) {
        case NEW_GENDER_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_GENDER_CATEGORY_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                genderCategory: action.payload
            }
        case NEW_GENDER_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_GENDER_CATEGORY_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}