import {
    CLEAR_ERRORS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_RESET, DELETE_PRODUCT_SUCCESS, GET_ALL_CATEGORY_SUCCESS, GET_ALL_PRODUCT_FAIL, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS, GET_PRODUCT_DETAIL_FAIL, GET_PRODUCT_DETAIL_REQUEST, GET_PRODUCT_DETAIL_SUCCESS, NEW_PRODUCT_FAIL,
    NEW_PRODUCT_REQUEST, NEW_PRODUCT_RESET, NEW_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_RESET, UPDATE_PRODUCT_SUCCESS
} from "../constants/type";

export const createProductReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_PRODUCT_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                product: action.payload
            }
        case NEW_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;

    }
}

export const productReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true,
            };

        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true,
            };
        case DELETE_PRODUCT_FAIL:
        case UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case DELETE_PRODUCT_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case UPDATE_PRODUCT_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const getAllProductReducer = (state = {products : {}}, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_REQUEST:
          return {
            loading: true,
            products: {},
          };
    
        case GET_ALL_PRODUCT_SUCCESS:
          return {
            products: action.payload,
          };
   
        case GET_ALL_PRODUCT_FAIL:
          return {
            loading: false,
            error: null,
          };
    
        case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
        default:
          return state;
      }
}

export const getProductDetailReducer = (state = {product : {}}, action) => {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_REQUEST:
          return {
            loading: true,
            product: {},
          };
    
        case GET_PRODUCT_DETAIL_SUCCESS:
          return {
            
            product: action.payload,
          };
   
        case GET_PRODUCT_DETAIL_FAIL:
          return {
            loading: false,
            error: null,
          };
    
        case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
        default:
          return state;
      }
}

export const getAllCategoryReducer = (state = {allCategories: {}}, action) => {
    if (action.type === GET_ALL_CATEGORY_SUCCESS) {
        return {
          ...state,
          allCategories: action.payload,
    
        };
      }
      return state;
}
