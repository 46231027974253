import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useSelector, useDispatch } from "react-redux";
import {getDailyRevenue, getMonthRevenue} from "../../actions/calculateAction"
import { useEffect } from "react";




const Featured = () => {
  const dispatch = useDispatch();
  const {dailyRevenue} = useSelector(state => state.getDailyRevenueReducer);
  const {monthRevenue} = useSelector(state => state.getMonthRevenueReducer);


  useEffect(() => {
    dispatch(getDailyRevenue());
    dispatch(getMonthRevenue());
   
  }, []);

  let daily = dailyRevenue.totalIncomeDaily;
  let month = monthRevenue.totalIncomeMonth;


  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Revenue</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={70} text={"70%"} strokeWidth={5} />
        </div>
        <p className="title">Total sales made today</p>
        <p className="amount">${daily}</p>
        <p className="desc">
          Previous transactions processing. Last payments may not be included.
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Target</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small"/>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              {/* <KeyboardArrowUpOutlinedIcon fontSize="small"/> */}
              <div className="resultAmount">${month}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
