export const NEW_APPAREL_CATEGORY_REQUEST = "NEW_APPAREL_CATEGORY_REQUEST";
export const NEW_APPAREL_CATEGORY_SUCCESS = "NEW_APPAREL_CATEGORY_SUCCESS";
export const NEW_APPAREL_CATEGORY_FAIL = "NEW_APPAREL_CATEGORY_FAIL";
export const NEW_APPAREL_CATEGORY_RESET = "NEW_APPAREL_CATEGORY_RESET";

export const NEW_BRAND_CATEGORY_REQUEST = "NEW_BRAND_CATEGORY_REQUEST";
export const NEW_BRAND_CATEGORY_SUCCESS = "NEW_BRAND_CATEGORY_SUCCESS";
export const NEW_BRAND_CATEGORY_FAIL = "NEW_BRAND_CATEGORY_FAIL";
export const NEW_BRAND_CATEGORY_RESET = "NEW_BRAND_CATEGORY_RESET";

export const NEW_GENDER_CATEGORY_REQUEST = "NEW_GENDER_CATEGORY_REQUEST";
export const NEW_GENDER_CATEGORY_SUCCESS = "NEW_GENDER_CATEGORY_SUCCESS";
export const NEW_GENDER_CATEGORY_FAIL = "NEW_GENDER_CATEGORY_FAIL";
export const NEW_GENDER_CATEGORY_RESET = "NEW_GENDER_CATEGORY_RESET";


export const NEW_APPAREL_IMAGE_REQUEST = "NEW_APPAREL_IMAGE_REQUEST";
export const NEW_APPAREL_IMAGE_SUCCESS = "NEW_APPAREL_IMAGE_SUCCESS";
export const NEW_APPAREL_IMAGE_FAIL = "NEW_APPAREL_IMAGE_FAIL";
export const NEW_APPAREL_IMAGE_RESET = "NEW_APPAREL_IMAGE_RESET";


export const NEW_BRAND_IMAGE_REQUEST = "NEW_BRAND_IMAGE_REQUEST";
export const NEW_BRAND_IMAGE_SUCCESS = "NEW_BRAND_IMAGE_SUCCESS";
export const NEW_BRAND_IMAGE_FAIL = "NEW_BRAND_IMAGE_FAIL";
export const NEW_BRAND_IMAGE_RESET = "NEW_BRAND_IMAGE_RESET";

export const NEW_CAROUSEL_IMAGE_REQUEST = "NEW_CAROUSEL_IMAGE_REQUEST";
export const NEW_CAROUSEL_IMAGE_SUCCESS = "NEW_CAROUSEL_IMAGE_SUCCESS";
export const NEW_CAROUSEL_IMAGE_FAIL = "NEW_CAROUSEL_IMAGE_FAIL";
// export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const NEW_CAROUSEL_IMAGE_RESET = "NEW_CAROUSEL_IMAGE_RESET";
